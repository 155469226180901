import copyToClipboard from "./dependencies/copyToClipboard";
import getParams from "./dependencies/getParams";
import numberWithCommas from "./dependencies/numberWithCommas";

import text from "./Dependencies.json";

import CryptoJS from 'crypto-js';
import ls from 'localstorage-slim';
import getBrowserFingerprint from 'get-browser-fingerprint';
import de from "date-fns/esm/locale/de/index.js";
import moment from "moment";

let fingerprint;
ls.config.encrypt = true;
try {
    fingerprint = getBrowserFingerprint();
    if(fingerprint){
        ls.config.secret = fingerprint;
    }else{
        ls.config.secret = 6078564;
    }
}catch(err) {
    ls.config.secret = 6078564;
}
class Dependencies {
    
    copyToClipboard = copyToClipboard;
    getParams = getParams;
    numberWithCommas = numberWithCommas;
    currencyName(currency){
        if(text[this.getFromLocalStorage("lang")].currencies[currency]){
            return text[this.getFromLocalStorage("lang")].currencies[currency];
        }else{
            return currency;
        }
    }
    integrationsName(integration){
        if(text[this.getFromLocalStorage("lang")].integrations[integration]){
            return text[this.getFromLocalStorage("lang")].integrations[integration];
        }else{
            return integration;
        }
    }
    paymentTypeName(type){
        if(text[this.getFromLocalStorage("lang")].types[type]){
            return text[this.getFromLocalStorage("lang")].types[type];
        }else{
            return type;
        }
    }
    paymentSolutionsName(type){
        if(text[this.getFromLocalStorage("lang")].payment_solutions[type]){
            return text[this.getFromLocalStorage("lang")].payment_solutions[type];
        }else{
            return type;
        }
    }
    statusName(status){
        if(text[this.getFromLocalStorage("lang")].status[status]){
            return text[this.getFromLocalStorage("lang")].status[status];
        }else{
            return status;
        }
    }
    bsMerchantStatus(bs_status){
        if(text[this.getFromLocalStorage("lang")].bs_status && text[this.getFromLocalStorage("lang")].bs_status[bs_status]){
            return text[this.getFromLocalStorage("lang")].bs_status[bs_status];
        }else{
            return bs_status;
        }
    }
    getAccountPermNames(list_of_perm_ids){
        let account_perms = [];
        if (this.getFromLocalStorage("permNames")) {
        let keys = dependencies.getFromLocalStorage("permsDict");
        keys = JSON.parse(keys);
        for(let i=0; i < list_of_perm_ids.length; i++){
            let perm_id = list_of_perm_ids[i]
            let perm_name = keys[perm_id];
            account_perms.push(perm_name);
        }
        return account_perms;
    }
    }
   

    getPermsIds(namesList){
        let idsArr = [];
        var keys = this.getFromLocalStorage("permNames");
        namesList.map( name => {
            Object.keys(keys).map(id =>{
                if(name === keys[id]){
                    idsArr.push(id);
                }
            });
        });
        return idsArr
    }
    permsName(perm){
        // console.log(perm, "permsssssssssss")
        if(this.getFromLocalStorage("lang") === "ar"){
            if(text[this.getFromLocalStorage("lang")].perms[perm]){
                return text[this.getFromLocalStorage("lang")].perms[perm];
            }else{
                return perm;
            }
        }else{
            return perm
        }
    }
     
    monthName(input){
        var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if( this.getFromLocalStorage("lang") === "ar" ){
          month = ['يناير','فبراير','مارس','ابريل','مايو','يونيو','يوليو','أغسطس','سبتمبر','أكتوبر','نوفمبر','ديسمبر'];
        }
        return month[input]
    }
    parseDate(input, format) {
        format = format || 'yyyy-mm-dd'; // default format
        var parts = input.match(/(\d+)/g), 
            i = 0, fmt = {};
        // extract date-part indexes from the format
        format.replace(/(yyyy|dd|mm)/g, function(part) {
            fmt[part] = i++; 
        });
        return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
    }
    parseReachCustomDate(input) {
        return moment(input).format('YYYY-MM-DD, h:mm a');
    }
    getDateOnlyFormat (currentDate){
        // Get the date, month, and year
        const year = currentDate.getFullYear();
        // JavaScript months are 0-indexed, so we add 1 to get the correct month
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        // Format the date as "YYYY-MM-DD"
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
 
        return formattedDate
    }
    pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    getNextDayDate(date){
        var nextDay = this.parseDate(date);
        nextDay.setDate(nextDay.getDate() + 1);
        var nextDayMonth = nextDay.getMonth() + 1;
        return nextDay.getFullYear() + '-' + this.pad(nextDayMonth) + '-' + this.pad(nextDay.getDate());
    }
    ParseFullDate(input) {
        let format = 'yyyy-mm-dd hh:mn:ss'; 
        var parts = input.match(/(\d+)/g), 
            i = 0, fmt = {};
        // extract date-part indexes from the format
        format.replace(/(yyyy|dd|mm|hh|mn|ss)/g, function(part) { fmt[part] = i++; });
        return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']], parts[fmt['hh']], parts[fmt['mn']], parts[fmt['ss']] );
    }
    IeDateFormate( input_date ){
        return input_date.slice(0, 19)
    }
    custom_date_format( input_date ){
        if(!input_date || input_date == "-"){
            return " - "
        }else{
            var d = this.ParseFullDate(this.IeDateFormate(input_date));
            // if(dependencies.getFromLocalStorage("acq_partner") == true && dependencies.getFromLocalStorage("partner_time_zone") !== undefined){
            //     let h = this.getTimeZoneOffset(dependencies.getFromLocalStorage("partner_time_zone")) - this.getTimeZoneOffset('Africa/Cairo');
            //     d = new Date(d.getTime() + (h*60*60*1000))
            //     //convert from local zone to selected zone
            //     //d = new Date(d.toLocaleString("en-EG", {timeZone: dependencies.getFromLocalStorage("partner_time_zone")}))
            // }
            var output_time = d.toLocaleTimeString().toUpperCase().replace(/([\d]+:[\d]+):[\d]+(\s\w+)/g, "$1$2");
            var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            if( this.getFromLocalStorage("lang") === "ar" ){
            month = ['يناير','فبراير','مارس','ابريل','مايو','يونيو','يوليو','أغسطس','سبتمبر','أكتوبر','نوفمبر','ديسمبر'];
                output_time = output_time.replace(/am/g,"صباحا");
                output_time = output_time.replace(/pm/g,"مساءً");
            }
            var output_date =  d.getDate() +" "+ month[d.getMonth()]  +" "+ d.getFullYear() + ', ' + output_time;
            
            //return d.toISOString().split('T')[0]
            return output_date
        }
    }
    normal_date_format_add_hours( input_date , hours ){
        var d = this.ParseFullDate(input_date);
        // if(dependencies.getFromLocalStorage("acq_partner") == true && dependencies.getFromLocalStorage("partner_time_zone") !== undefined){
        //     let h = this.getTimeZoneOffset(dependencies.getFromLocalStorage("partner_time_zone")) - this.getTimeZoneOffset('Africa/Cairo');
        //     h = h + hours;
        //     d = new Date(d.getTime() + (h*60*60*1000))
        //     //convert from local zone to selected zone
        //     //d = new Date(d.toLocaleString("en-EG", {timeZone: dependencies.getFromLocalStorage("partner_time_zone")}))
        // }else{
            let h = hours;
            d = new Date(d.getTime() + (h*60*60*1000))
        // }
        var output_time = d.toLocaleTimeString().toUpperCase().replace(/([\d]+:[\d]+):[\d]+(\s\w+)/g, "$1$2");
        var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if( this.getFromLocalStorage("lang") === "ar" ){
          month = ['يناير','فبراير','مارس','ابريل','مايو','يونيو','يوليو','أغسطس','سبتمبر','أكتوبر','نوفمبر','ديسمبر'];
            output_time = output_time.replace(/am/g,"صباحا");
            output_time = output_time.replace(/pm/g,"مساءً");
        }
        var output_date =  d.getDate() +" "+ month[d.getMonth()]  +" "+ d.getFullYear() + ', ' + output_time;
        
        //return d.toISOString().split('T')[0]
        return output_date
    }
    getTimeZoneOffset(timeZone) {
        let date = new Date()
        // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
        const options = {timeZone, calendar: 'iso8601', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false};
        const dateTimeFormat = new Intl.DateTimeFormat(undefined, options);
        const parts = dateTimeFormat.formatToParts(date);
        const map = new Map(parts.map(x => [x.type, x.value]));
        const year = map.get('year');
        const month = map.get('month');
        const day = map.get('day');
        const hour = map.get('hour');
        const minute = map.get('minute');
        const second = map.get('second');
        const ms = date.getMilliseconds().toString().padStart(3, '0');
        const iso = `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;
      
        // Lie to the Date object constructor that it's a UTC time.
        const lie = new Date(iso + 'Z');
        // Return the difference in timestamps, as minutes
        // Positive values are West of GMT, opposite of ISO 8601
        // this matches the output of `Date.getTimeZoneOffset`
        return (lie - date) / 60 / 60 / 1000;
      }
    getDateOnlyFormat(currentDate){
        const year = currentDate.getFullYear();
        // JavaScript months are 0-indexed, so we add 1 to get the correct month
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`; // Format as "YYYY-MM-DD"

        return formattedDate
    }
    boolName(bool){
        let str = bool;
        if(this.getFromLocalStorage("lang") === "ar"){
            if(bool == true || bool == "true"){
                str = "نعم"
            }else{
                str = "لا"
            }
        }else {
            if(bool == true || bool == "true"){
                str = "Yes"
            }else{
                str = "No"
            }
        }
        return str
    }
    isLiveName(bool){
        let str = bool;
        if(this.getFromLocalStorage("lang") === "ar"){
            if(bool == true || bool == "true"){
                str = "نشط"
            }else{
                str = "اختبار"
            }
        }else {
            if(bool == true || bool == "true"){
                str = "Live"
            }else{
                str = "Test"
            }
        }
        return str

    }
    getTenure(input) {
        if (typeof input === 'object' && input.month) {
            return input.month;
        } else if (typeof input === 'number' || typeof input === 'string') {
            return input;
        } else {
            return "-"; 
        }
    }
    getLastName(fullName){
        let str = fullName.trim()
        let array = str.split(' ');
        return array[array.length - 1];
    }
    getFirstName(fullName){
        let str = fullName.trim()
        let array = str.split(' ');
        return array[0];
    }
    getAccountType(){
        return this.getFromLocalStorage("type");
    }
    convertAmount(amount,currency){
        if(amount === "-"){
            amount = 0;
        }
        return dependencies.numberWithCommas((amount/1).toFixed(2)) + " " + this.currencyName(currency);
    }
    convertCentsAmountFull(amount,currency){
        if(amount === "-"){
            amount = 0;
        }
        if(currency === "JOD"){
            return dependencies.numberWithCommas(amount/1000) + " " + this.currencyName(currency)
        }else{
            return dependencies.numberWithCommas(amount/100) + " " + this.currencyName(currency)
        }
    }
    convertCentsAmountFullNoCurr(amount){
        return dependencies.numberWithCommas(amount/100)
    }
    getCurrentLang =() =>{
        if (this.getFromLocalStorage("lang")) {
            return this.getFromLocalStorage("lang")
        } else {
            return "en"
        }
    }
    convertCentsAmount(amount,currency){
        if(currency === "JOD"){
            return (amount/1000) + " " + this.currencyName(currency)
        }else{
            return (amount/100) + " " + this.currencyName(currency)
        }
    }
    convertCentsAmountNoCurr(amount,currency){
        if(currency === "JOD"){
            return (amount/1000)
        }else{
            return (amount/100)
        }
    }

    //permissions
    viewIntegrationsPerm(){
        return (this.getFromLocalStorage("permNames")).includes("can view payment integration");
    }

    getCents (currency){
        if(currency === "JOD" || currency === "OMR" || currency === "KWD"){
            return 1000
        }else{
            return 100
        }
    }

    addToLocalStorage (key, value){
        ls.set(
            CryptoJS.HmacSHA1(key, "sdfgsdfg#@$23dsf" + fingerprint),
            value
        );
    }

    getFromLocalStorage (key){
        let value = ls.get(CryptoJS.HmacSHA1(key, "sdfgsdfg#@$23dsf" + fingerprint));
        return value;
    }

    deleteFromLocalStorage (key){
        let value = ls.remove(CryptoJS.HmacSHA1(key, "sdfgsdfg#@$23dsf" + fingerprint));

        return value;
    }

    getAmplitudeApiKey (){
        let key = "af23be78b297f530fea828fee3860a8f";

        return key;
    }
}

export let dependencies = new Dependencies();
